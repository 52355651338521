.BDDA-A1-action-btn-container,
.BDDA-A1-cancelled-text-container {
  width: 336px;
  margin: -29px 38px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.BDDA-A1-cancelled-text-container {
  justify-content: center;
}

#BDDA-A1-cancelled-text {
  font-size: 12px;
  font-style: italic;
  color: #66788a;
  font-stretch: normal;
  letter-spacing: normal;
}

.BDDA-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}
