.CPDC-A1-wrapper {
  padding: 16px 8px 4px 8px;
}
.CPDC-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.CPDC-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.CPDC-A1-input-field-container label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.CPDC-A1-margin-bottom-32 {
  margin-bottom: 32px;
}

.CPDC-A1-eye-icon-container {
  min-height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 14px;
  border-radius: 3px;
}

.CPDC-A1-eye-icon-container:hover {
  cursor: pointer;
}
