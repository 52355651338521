.EPP-A1-button {
  padding: 0 !important;
  justify-content: center !important;
}

.EPP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.EPP-A1-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 900px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
  padding: 32px;
}

#EPP-A1-confirmation-dialog-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

.EPP-A1-row-container,
.EPP-A1-col-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.EPP-A1-col-container {
  flex-direction: column;
}

.EPP-A1-radio-input {
  align-items: center;
}

.EPP-A1-radio-input span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
}

.EPP-A1-price-slot-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.EPP-A1-vertical-line-decorator {
  height: 60px;
  border-right: 1px solid #e7e9ed;
}

.EPP-A1-slot-discount-actions-container {
  align-self: flex-end;
}

.EPP-A1-remove-time-slot-btn-container {
  padding-top: 26px;
}

.EPP-A1-horizontal-line-decorator {
  width: 834px;
  border-bottom: 1px solid #e7e9ed;
}

// -----------------------------------------------------------------------
// Label()
// -----------------------------------------------------------------------
#EPP-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

#EPP-A1-label-type-two {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#EPP-A1-label-type-three {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
}

// -----------------------------------------------------------------------
// Margin()
// -----------------------------------------------------------------------
.EPP-A1-margin-top-24 {
  margin-top: 24px;
}

.EPP-A1-margin-top-18 {
  margin-top: 18px;
}

.EPP-A1-margin-top-32 {
  margin-top: 32px;
}

.EPP-A1-margin-top-16 {
  margin-top: 16px;
}

.EPP-A1-margin-right-16 {
  margin-right: 16px;
}

.EPP-A1-margin-right-32 {
  margin-right: 32px;
}

.EPP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.EPP-A1-margin-left-48 {
  margin-left: 48px;
}

.EPP-A1-margin-top-68 {
  margin-top: 68px;
}
