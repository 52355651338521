.CMPP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.CMPP-A1-create-membership-package-form-container {
  width: 813px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
}

.CMPP-A1-row-type-one-content,
.CMPP-A1-row-type-two-content,
.CMPP-A1-top-row-type-three-content,
.CMPP-A1-top-row-type-two-content,
.CMPP-A1-row-content-top-4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 752px;
  margin: 32px auto 0 auto;
}

.CMPP-A1-row-content-top-4 {
  margin: 4px auto 0 auto;
}

.CMPP-A1-row-type-two-content {
  margin-top: 24px;
}

.CMPP-A1-top-row-type-three-content {
  margin: 68px auto;
}

.CMPP-A1-col-type-one-content,
.CMPP-A1-col-type-two-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.CMPP-A1-col-type-two-content {
  margin-right: 24px;
}

#CMPP-A1-big-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

.CMPP-A1-text-input-field-type-one-container,
.CMPP-A1-radio-input-wrapper {
  margin-right: 40px;
}

.CMPP-A1-text-input-field-type-one-container div,
.CMPP-A1-text-input-field-type-two-container div {
  margin: 0;
}

.CMPP-A1-text-input-field-type-one-container label,
.CMPP-A1-text-input-field-type-two-container label,
#CMPP-A1-medium-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.CMPP-A1-package-type-radio-input-label {
  margin-bottom: 0;
}

.CMPP-A1-text-input-field-type-one {
  width: 336px !important;
  height: 32px !important;
}

.CMPP-A1-text-input-field-type-two {
  width: 160px !important;
  height: 32px !important;
}

.CMPP-A1-radio-input-label-container {
  margin-bottom: 8px;
}

.CMPP-A1-radio-input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.CMPP-A1-radio-input-container label {
  margin-top: 6px;
  margin-bottom: 0;
}

.CMPP-A1-radio-input span {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #435a6f !important;
  align-self: flex-end !important;
}

.CMPP-A1-horizontal-line {
  width: 752px;
  border-bottom: 1px solid #e4e7eb;
  margin: 32px auto 0 auto;
}

.CMPP-A1-vertical-line {
  height: 112px;
  border-right: 1px solid #e4e7eb;
  margin: 0 58px;
}

.CMPP-A1-general-row-type-one-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 24px;
}

.CMPP-A1-disabled-label,
.CMPP-A1-text-input-field-container-disabled label {
  cursor: not-allowed;
  color: #dbdfe3;
}

#CMPP-A1-medium-disabled-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  cursor: not-allowed;
  color: #dbdfe3;
  text-transform: uppercase;
  margin-bottom: 8px;
}

#CMPP-A1-large-type-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: #234361;
}

.CMPP-A1-margin-right-52 {
  margin-right: 52px;
}

.CMPP-A1-margin-left-40 {
  margin-left: 40px;
}

.CMPP-A1-align-self-flex-end {
  align-self: flex-end !important;
}

// -----------------------------------------------------------------------
// confirm dismiss dialog
// -----------------------------------------------------------------------
#CMPP-A1-medium-dark-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.CMPP-A1-margin-left-40 {
  margin-left: 40px;
}
