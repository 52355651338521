.MPCP-A1-button {
  padding: 0 !important;
  justify-content: center !important;
}

.MPCP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.MPCP-A1-confirm-package-form-container {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  width: 813px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
}

.MPCP-A1-membership-packages-checkout-form-left-panel-container,
.MPCP-A1-membership-packages-checkout-form-right-panel-container {
  width: 406px;
}

.MPCP-A1-membership-packages-checkout-form-left-panel-container {
  border-right: 1px solid #e4e7eb;
}

.MPCP-A1-left-panel-col-content,
.MPCP-A1-right-panel-col-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 346px;
  margin: 0 auto;
  padding: 5px 5px 68px 5px;
  overflow: hidden;
}

.MPCP-A1-row-container,
.MPCP-A1-row-344-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.MPCP-A1-row-344-container {
  width: 344px;
  align-items: center;
}

.MPCP-A1-text-input-field-container label {
  margin-bottom: 8px !important;
}

.MPCP-A1-text-input-field-container input {
  font-size: 12px !important;
  margin: 0 !important;
}

.MPCP-A1-horizontal-line-decorator {
  width: 344px;
  border-top: 1px solid #e4e7eb;
}

.MPCP-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#MPCP-A1-input-field-label,
.MPCP-A1-text-input-field-container label {
  font-size: 11px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.45 !important;
  letter-spacing: 0.3px !important;
  color: #66788a !important;
  text-transform: uppercase !important;
}

.MPCP-A1-right-panel-col-content {
  width: 354px;
}

.MPCP-A1-success-msg-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 344px;
  max-width: 344px;
  height: 32px;
}

.MPCP-A1-success-msg-left-pane {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 264px;
}

.MPCP-A1-inline-alert div:first-child {
  align-self: flex-start !important;
}

.MPCP-A1-right-panel-left-row-max-156,
.MPCP-A1-right-panel-right-row-max-156 {
  width: 156px;
  max-width: 156px;
  overflow-wrap: break-word;
}

.MPCP-A1-right-panel-right-row-max-156 {
  text-align: right;
  word-break: break-all;
  margin-left: 32px;
}

.MPCP-A1-width-104 {
  width: 104px;
  max-width: 104px;
}

.MPCP-A1-width-88 {
  width: 88px;
  max-width: 88px;
}

.MPCP-A1-width-86 {
  width: 86px;
  max-width: 86px;
}

.MPCP-A1-width-256 {
  width: 256px;
  max-width: 256px;
  text-align: right;
  overflow-wrap: break-word;
}

.MPCP-A1-word-break-all {
  word-break: break-all;
}

#MPCP-A1-confirmation-dialog-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}
// -----------------------------------------------------------------------
// margin
// -----------------------------------------------------------------------
.MPCP-A1-margin-top-32 {
  margin-top: 32px;
}

.MPCP-A1-margin-top-16 {
  margin-top: 16px;
}

.MPCP-A1-margin-top-24 {
  margin-top: 24px;
}

.MPCP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.MPCP-A1-margin-right-8 {
  margin-right: 8px;
}

.MPCP-A1-margin-right-16 {
  margin-right: 16px;
}

.MPCP-A1-invalid-input-msg-container {
  margin-top: 8px;
}

.MPCP-A1-inline-alert {
  align-items: center !important;
}

#MPCP-A1-invalid-input-msg {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bf0e08;
}

// -----------------------------------------------------------------------
// label
// -----------------------------------------------------------------------
#MPCP-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

#MPCP-A1-label-type-two {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#MPCP-A1-label-type-three {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #66788a;
}

#MPCP-A1-label-type-four {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #425a70;
}

#MPCP-A1-label-type-five {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#MPCP-A1-label-type-six {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #234361;
}

#MPCP-A1-label-type-seven {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  text-align: right;
  color: #425a70;
}

#MPCP-A1-medium-dark-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#MPCP-A1-bigger-label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #234361;
}

#MPCP-A1-small-light-label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#MPCP-A1-small-dark-label {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}
