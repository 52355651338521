.CSDC-A1-wrapper {
  max-width: 512px;
}

.CSDC-A1-row-container,
.CSDC-A1-col-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.CSDC-A1-col-container {
  flex-direction: column;
}

.CSDC-A1-support-executive-detail-container {
  padding: 16px;
  width: 512px;
  min-height: 64px;
  height: auto;
  border: 1px solid #ebebeb;
  background-color: #f8f8f8;
}

.CSDC-A1-email-detail-container {
  min-height: 108px;
}

.CSDC-A1-phone-logo-container,
.CSDC-A1-email-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #01579b;
  border-radius: 50%;
}

.CSDC-A1-left-section {
  width: 352px;
}

.CSDC-A1-email-logo-section,
.CSDC-A1-right-section {
  align-items: center;
}

.CSDC-A1-right-section {
  width: 160px;
}

#CSDC-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
}

#CSDC-A1-label-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#CSDC-A1-label-type-three {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}

#CSDC-A1-label-type-four {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.04px;
  color: #66788a;
}

#CSDC-A1-label-type-five {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.05px;
  color: #425a70;
}

#CSDC-A1-label-type-five:hover {
  color: #1070ca;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.CSDC-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

.CSDC-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.CSDC-A1-margin-bottom-4 {
  margin-bottom: 4px;
}

.CSDC-A1-margin-bottom-28 {
  margin-bottom: 28px;
}

.CSDC-A1-margin-right-8 {
  margin-right: 8px;
}

.CSDC-A1-margin-right-100 {
  margin-right: 100px;
}

.CSDC-A1-margin-top-24 {
  margin-top: 24px;
}
