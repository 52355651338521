.TDC-A1-container,
.TDC-A1-row-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.TDC-A1-button {
  justify-content: center !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.TDC-A1-row-container {
  flex-direction: row;
}

.TDC-A1-table-text-header-cell,
.TDC-A1-table-cell {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.TDC-A1-flex-basis-141 {
  flex-basis: 141px !important;
}

.TDC-A1-flex-basis-171 {
  flex-basis: 171px !important;
}

.TDC-A1-flex-basis-155 {
  flex-basis: 155px !important;
}

.TDC-A1-flex-basis-112 {
  flex-basis: 112px !important;
}

.TDC-A1-flex-basis-270 {
  flex-basis: 270px !important;
}

.TDC-A1-flex-basis-158 {
  flex-basis: 158px !important;
}

.TDC-A1-table-body {
  // min-width: 849px !important;
  min-width: 737px !important;
  min-height: 240px !important;
  max-height: 380px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.TDC-A1-table-row {
  min-height: 84px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.TDC-A1-table-cell-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  word-break: break-all;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#TDC-A1-label-type-one {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: #234361;
}

#TDC-A1-label-type-two {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

#TDC-A1-label-type-three {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#TDC-A1-label-type-four {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.TDC-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

.TDC-A1-margin-bottom-8 {
  margin-bottom: 8px;
}
