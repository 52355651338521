.PF-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding: 32px;
}

.PF-A1-invalid-input-msg-container {
  margin-top: 8px;
}

.PF-A1-inline-alert {
  align-items: center;
}
.PF-A1-inline-alert div:first-child {
  align-self: flex-start;
}

#PF-A1-info-input-msg {
  font-size: medium;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #084b8a;
}
