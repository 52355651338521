.CCT-A1-wrapper {
  padding: 16px 8px 4px 6px;
}

.CCT-A1-wrapper > :first-child {
  margin-top: 0;
}

.CCT-A1-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 450px;
  max-width: 450px;
  padding-bottom: 16px;
  margin-top: 24px;
  border-bottom: 1px solid #e4e7eb;
}

.CCT-A1-table-container {
  width: 1068px;
  min-width: 1068px;
  border: 1px solid #e4e7eb;
  border-bottom: 0;
}

.CCT-A1-flex-basis-220 {
  flex-basis: 220px !important;
}

.CCT-A1-pagination-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  width: 1068px;
}

.CCTP-A1-table-container {
  width: 450px;
  min-width: 450px;
  border: 1px solid #e4e7eb;
  border-bottom: 0;
}

.CCTP-A1-flex-basis-130 {
  flex-basis: 150px !important;
}

.CCTP-A1-flex-basis-120 {
  flex-basis: 110px !important;
}

.CCTP-A1-flex-basis-70 {
  flex-basis: 70px !important;
}

.CCTP-A1-table-row {
  min-height: 50px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.Text-Align-Center {
  padding-left: 25px;
}
