.BBDOSC-A1-action-btn-container,
.BBDOSC-A1-cancelled-text-container {
  width: 336px;
  margin: 24px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BBDOSC-A1-cancelled-text-container {
  justify-content: center;
}

#BBDOSC-A1-cancelled-text {
  font-size: 12px;
  font-style: italic;
  color: #66788a;
  font-stretch: normal;
  letter-spacing: normal;
}

.BBDOSC-A1-button {
  justify-content: center;
  padding: 0;
}
