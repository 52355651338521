.BRF-A1-wrapper,
.BRF-A1-col-container,
.BRF-A1-show-transaction-for-radio-popover-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BRF-A1-show-transaction-for-radio-popover-wrapper {
  width: 184px;
  height: 112px;
  padding: 8px 0 16px 14px;
}

.BRF-A1-button-as-select {
  padding: 0 16px;
  justify-content: space-between;
  text-transform: capitalize;
}

#BRF-A1-type-one-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

// -----------------------------------------------------------------------
// Margin()
// -----------------------------------------------------------------------
.BRF-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.BRF-A1-margin-bottom-4 {
  margin-bottom: 4px;
}
