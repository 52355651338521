.MPDC-A1-wrapper {
  max-height: 480px;
  overflow: auto;
  padding-right: 16px;
}

.MPDC-A1-wrapper > :first-child {
  margin-top: 0;
}

.MPDC-A1-button {
  padding: 0;
  justify-content: center;
}

.MPDC-A1-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 528px;
  max-width: 528px;
  padding-bottom: 16px;
  margin-top: 24px;
  border-bottom: 1px solid #e4e7eb;
}

.MPDC-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 528px;
}

.MPDC-A1-left-pane-content,
.MPDC-A1-right-pane-content {
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 16px;
  overflow-wrap: break-word;
}

.MPDC-A1-right-pane-content {
  margin-right: 0;
}

.MPDC-A1-max-width-264 {
  width: auto;
  max-width: 264px;
}

.MPDC-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

#MPDC-A1-text-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#MPDC-A1-text-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #66788a;
}

#MPDC-A1-text-type-three {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: #425a70;
}

#MPDC-A1-text-type-four {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #d9822b;
}

.MPDC-A1-align-items-center {
  align-items: center;
}

.MPDC-A1-justify-content-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.MPDC-A1-justify-content-flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MPDC-A1-width-328 {
  width: 328px;
  min-width: 328px;
  max-width: 328px;
  overflow-wrap: break-word;
}

.MPDC-A1-width-184 {
  width: 184px;
  min-width: 184px;
  max-width: 184px;
}

.MPDC-A1-width-0-184 {
  width: 184px;
  min-width: 0px;
  max-width: 184px;
}

.MPDC-A1-width-0-140 {
  width: 140px;
  min-width: 0px;
  max-width: 140px;
}

.MPDC-A1-width-0-170 {
  width: 170px;
  min-width: 0px;
  max-width: 170px;
}