.RS-A1-wrapper {
  min-width: 632px;
  max-width: 632px;
  overflow-wrap: break-word;
}

.RS-A1-report-type-container {
  margin-bottom: 8px;
}

#RS-A1-type-one-label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: #234361;
  text-transform: capitalize;
}

#RS-A1-type-two-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.05px;
  color: #425a70;
}
