.AMLP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.AMLP-A1-members-list-container {
  width: 1000px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
  margin-bottom: 40px;
}

.AMLP-A1-table-container {
  width: 741px;
  max-width: 741px;
  max-height: calc(100vh - 220px);
  border: 1px solid #e4e7eb;
}

.AMLP-A1-virtual-table-body {
  height: calc(100vh - 252px) !important;
}

.AMLP-A1-table-text-header-cell,
.AMLP-A1-table-cell {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.AMLP-A1-flex-basis-252 {
  flex-basis: 252px !important;
}
.AMLP-A1-flex-basis-241 {
  flex-basis: 241px !important;
}
.AMLP-A1-flex-basis-182 {
  flex-basis: 182px !important;
}
.AMLP-A1-flex-basis-48 {
  flex-basis: 48px !important;
}

.AMLP-A1-table-row {
  align-items: flex-start !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.AMLP-A1-table-row:hover {
  cursor: default;
}

.AMLP-A1-table-cell-data-container,
.AMLP-A1-member-avatar-and-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AMLP-A1-avatar {
  align-self: flex-start !important;
}

#AMLP-A1-label-type-one {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #1070ca;
  text-decoration: underline;
}

#AMLP-A1-label-type-one:hover {
  cursor: pointer;
  color: #084b8a;
}

#AMLP-A1-label-type-two {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#AMLP-A1-label-type-three {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #007489;
  text-transform: uppercase;
}

.AMLP-A1-icon-btn:focus {
  box-shadow: none !important;
}
