.PHAAN-A1-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
}

.PHAAN-A1-actions-and-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
}

#PHAAN-A1-heading {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #234361;
}
