.SPDC-A1-col-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.SPDC-A1-last-col-container {
  margin-bottom: 40px;
}

#SPDC-A1-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.SPDC-A1-danger-inline-alert {
  color: #bf0e08;
}

.SPDC-A1-success-inline-alert {
  color: #00783e;
}

.SPDC-A1-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid #e4e7eb;
}
