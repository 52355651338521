.search-table-pagination,
.RBT-A1-pagination,
.MT-A1-pagination {
  li {
    border-radius: 3px;
  }

  .ant-pagination-item a,
  .ant-pagination-item-active a {
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #66788a;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .ant-pagination-item-active a {
    color: #1070ca;
  }

  .ant-pagination-item {
    border: none;
    background-color: transparent;
  }

  .ant-pagination-item-active {
    background-color: rgba(16, 112, 202, 0.08);
  }
}
