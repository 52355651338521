.MDOD-A1-wrapper {
  width: 368px;
  height: calc(100vh - 228px);
  min-height: calc(100vh - 228px);
  margin: 16px auto 0 auto;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
  overflow-y: auto;
  padding-bottom: 32px;
}

.MDOD-A1-row-top-16-container,
.MDOD-A1-row-top-24-container,
.MDOD-A1-336-row-container {
  width: 336px;
  max-width: 336px;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.MDOD-A1-336-row-container {
  margin: 0 auto;
}

.MDOD-A1-align-self-center {
  align-self: center;
}

.MDOD-A1-row-top-24-container {
  margin-top: 24px;
}

.MDOD-A1-margin-right-24 {
  margin-right: 24px;
}

.MDOD-A1-margin-top-8 {
  margin-top: 8px;
}

.MDOD-A1-left-160-col-container,
.MDOD-A1-right-160-col-container,
.MDOD-A1-336-col-container {
  min-width: 156px;
  max-width: 156px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MDOD-A1-address-container {
  max-width: 336px;
  overflow-wrap: break-word;
}

.MDOD-A1-right-160-col-container {
  margin-right: 0;
}

.MDOD-A1-width-288 {
  min-width: 288px;
  max-width: 288px;
  overflow-wrap: break-word;
}

.MDOD-A1-336-col-container {
  min-width: 336px;
  max-width: 336px;
  margin-right: 0;
}

#MDOD-A1-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#MDOD-A1-data-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: #234361;
}

.MDOD-A1-horizontal-line-decorator,
.MDOD-A1-horizontal-line-decorator-margin-bottom-0 {
  width: 336px;
  margin: 24px auto;
  border-bottom: 1px solid #e4e7eb;
}

.MDOD-A1-horizontal-line-decorator-margin-bottom-0 {
  margin-bottom: 0;
}

#MDOD-A1-dark-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

.MDOD-A1-credits-container {
  width: 110px;
  margin-right: 32px;
  text-align: right;
  word-break: break-all;
}

#MDOD-A1-data-underlined-text {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #1070ca;
  text-decoration: underline;
}

#MDOD-A1-data-underlined-text:hover {
  cursor: pointer;
  color: #084b8a;
}

.MDOD-A1-member-details-actions-wrapper {
  width: 368px;
  height: 80px;
  position: absolute;
  margin: -4px 16px 0 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 -5px 10px -5px rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
  z-index: 3;
}

#MDOD-A1-label-type-four {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
  text-decoration: underline;
}

.MDOD-A1-label-type-four:hover {
  cursor: pointer;
  color: #234361;
}

#MDOD-A1-label-type-five {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #007489;
  text-transform: uppercase;
}
