.BPG-A1-wrapper {
  position: relative;
  width: 888px;
  height: 488px;
  margin: 100px 32px 32px 76px;
}

.BPG-A1-arrow-vector {
  position: absolute;
  top: -118px;
  left: 100px;
}

.BPG-A1-msg-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: solid 1px #e4e7eb;
  background-color: #fafafa;
}

#BPG-A1-msg {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.07px;
  color: rgba(102, 120, 138, 0.41);
  z-index: 5;
}
