// -----------------------------------------------------------------------
// Custom Styling for React TimePicker
// -----------------------------------------------------------------------
.CTP-A1-wrapper {
  .react-datepicker__input-container input,
  .react-datepicker__input-container input:disabled {
    width: 124px;
    height: 32px;
    padding: 0px 8px;
    cursor: pointer;
    border-radius: 3px;
    background-image: linear-gradient(to top, #f4f5f7, #ffffff);
    border: none;
    color: #425a70;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09);
    font-size: 12px;
  }

  .react-datepicker__input-container input:disabled {
    cursor: not-allowed;
    color: #bdbdbf;
  }

  .react-datepicker-popper {
    z-index: 6 !important;
  }

  .react-datepicker--time-only {
    width: 124px !important;
  }

  .react-datepicker__time-container,
  .react-datepicker__header,
  .react-datepicker__header--time,
  .react-datepicker__time-box {
    width: 100% !important;
  }
}
