.MDDA-A1-action-btn-container {
  width: 336px;
  margin: 24px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#MDDA-A1-type-one-label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  font-style: normal;
  font-stretch: normal;
  color: #425a70;
}

.MDDA-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}
