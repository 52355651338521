.CS-A1-wrapper {
  min-width: 640px;
  min-height: 640px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CS-A1-icon-container {
  margin-bottom: 32px;
}

#CS-A1-label-type-one {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.07px;
  color: rgba(102, 120, 138, 0.41);
}
