.BC-A1-button {
  padding: 0 !important;
  justify-content: center !important;
}

.border-button{
  padding: 0 !important;
  justify-content: center !important;
  border:1.2px solid #0679c5;
}

.BC-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.BC-A1-booking-checkout-form-container {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  width: 813px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
}

.BC-A1-booking-checkout-form-left-panel-container,
.BC-A1-booking-checkout-form-right-panel-container {
  width: 406px;
}

.BC-A1-booking-checkout-form-left-panel-container {
  border-right: 1px solid #e4e7eb;
}

.BC-A1-left-panel-col-content,
.BC-A1-right-panel-col-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 346px;
  margin: 0 auto;
  padding: 5px 5px 68px 5px;
  overflow: hidden;
}

.BC-A1-right-panel-left-row-max-156,
.BC-A1-right-panel-right-row-max-156 {
  width: 156px;
  max-width: 156px;
  overflow-wrap: break-word;
}

.BC-A1-right-panel-right-row-max-156 {
  text-align: right;
  word-break: break-all;
  margin-left: 32px;
}

.BC-A1-word-break-all {
  word-break: break-all;
}

.BC-A1-right-panel-col-content {
  width: 354px;
}

.BC-A1-margin-top-32 {
  margin-top: 32px;
}

.BC-A1-margin-top-24 {
  margin-top: 24px;
}

.BC-A1-margin-top-48 {
  margin-top: 48px;
}

.BC-A1-margin-top-17 {
  margin-top: 17px;
}

.BC-A1-margin-top-8 {
  margin-top: 8px;
}

.BC-A1-margin-top-16 {
  margin-top: 16px;
}

.BC-A1-margin-bottom-8,
.BC-A1-text-input-field-container label {
  margin-bottom: 8px;
}

.BC-A1-margin-right-8 {
  margin-right: 8px;
}

.BC-A1-margin-right-16 {
  margin-right: 16px;
}

.BC-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BC-A1-text-input-field-container input,
.BC-A1-text-input-field-container textarea {
  font-size: 12px;
  margin: 0;
}

#BC-A1-input-field-label,
.BC-A1-text-input-field-container label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#BC-A1-big-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

#BC-A1-bigger-label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #234361;
}

#BC-A1-medium-dark-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#BC-A1-medium-light-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
}

#BC-A1-small-light-label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#BC-A1-small-dark-label {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}

.BC-A1-width-120 {
  width: 120px;
  max-width: 120px;
}

.BC-A1-width-88 {
  width: 88px;
  max-width: 88px;
}

.BC-A1-width-104 {
  width: 104px;
  max-width: 104px;
}

.BC-A1-width-86 {
  width: 86px;
  max-width: 86px;
}

.BC-A1-row-container,
.BC-A1-row-344-container,
.BC-A1-reverse-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.BC-A1-row-344-container {
  width: 344px;
  align-items: center;
}

.BC-A1-reverse-row-container {
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  width: 344px;
}

.BC-A1-success-msg-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 344px;
  max-width: 344px;
  height: 32px;
}

.BC-A1-success-msg-left-pane {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 264px;
}

.BC-A1-invalid-input-msg-container {
  margin-top: 8px;
}

.BC-A1-horizontal-line-decorator {
  width: 344px;
  border-top: 1px solid #e4e7eb;
}



.BC-A1-inline-alert {
  align-items: center;
}
.BC-A1-inline-alert div:first-child {
  align-self: flex-start;
}

#BC-A1-invalid-input-msg,
#BC-A1-info-input-msg {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bf0e08;
}

.BC-A1-info-input-msg {
  color: #084b8a;
}

// -----------------------------------------------------------------------
// confirm dismiss dialog
// -----------------------------------------------------------------------
.BC-A1-confirm-dismiss-dialog-body-container,
.BC-A1-confirm-dismiss-dialog-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 12px 28px 12px;
}

#BC-A1-confirm-dismiss-dialog-message {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

.BC-A1-confirm-dismiss-dialog-footer-container {
  justify-content: flex-end;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid #e4e7eb;
  margin: 0;
}

// -----------------------------------------------------------------------
// redeem credits popover
// -----------------------------------------------------------------------
.BC-A1-redeem-credits-popover-container {
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BC-A1-redeem-credits-cross-btn-container {
  margin-top: 0;
  margin-bottom: 16px;
}

.BC-A1-redeem-credits-top-div {
  width: 280px;
}

#BC-A1-avail-member-credits-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
}

.BC-A1-redeem-credits-action-btn-container,
.BC-A1-redeem-credits-cross-btn-container {
  width: 330px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: flex-end;
}
