.country-select-menu-country-data-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 28px;
  border-bottom: 1px solid #e4e7eb;
}

.country-select-menu-country-data-container:hover {
  cursor: pointer;
  background-color: #ddebf7;
}

.country-select-menu-country-flag-container {
  width: 24px;
  height: 16px;
  margin-left: 4px;
}

.country-select-menu-country-flag {
  width: 24px;
  height: 16px;
}

.country-select-menu-country-name-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
}

#country-select-menu-country-name {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

.country-select-menu-country-calling-code-container {
  margin-left: 4px;
  margin-right: 4px;
}

#country-select-menu-country-calling-code {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #66788a;
}

.country-select-menu-button-as-select {
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 8px !important;
  padding-right: 12px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.country-select-menu-country-flag-small {
  width: 22px;
  height: 14px;
  margin-right: 4px;
}
