.CBDC-A1-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.CBDC-A1-refund-type-radio-btns-container {
  display: flex;
  flex-direction: row;
}

.CBDC-A1-radio-btn {
  align-items: center !important;
  margin-top: 0 !important;
  margin-bottom: 16 !important;
}

.CBDC-A1-radio-btn span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
}

.CBDC-A1-action-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #e4e7eb;
  margin-top: 16px;
  padding-top: 16px;
  width: 100%;
}

.CBDC-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#CBDC-A1-label-type-one {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.1px;
  color: #425a70;
  font-style: normal;
  font-stretch: normal;
}

#CBDC-A1-label-type-two {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #66788a;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.CBDC-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

.CBDC-A1-margin-bottom-8 {
  margin-bottom: 8px;
}
