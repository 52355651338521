.MV-A1-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 64px;
  background-color: #084b8a;
}

.MV-A1-playo-logo {
  width: 86px;
  height: 25px;
}

.MV-A1-body-container {
  position: relative;
}

.MV-A1-football-ground-mobile-img {
  width: 100%;
  height: auto;
}

.MV-A1-hello-partner-label-container {
  position: absolute;
  bottom: 22%;
  left: 5%;
}

.MV-A1-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 32px 16px;
  background-color: #ffffff;
}

.MV-A1-store-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.MV-A1-google-play-logo-img,
.MV-A1-app-store-logo-img {
  max-width: 128px;
}

.MV-A1-link:hover {
  cursor: pointer;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#MV-A1-label-type-one {
  font-size: 27px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.19px;
  color: #ffffff;
}

#MV-A1-label-type-two {
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.26px;
  color: #ffffff;
}

#MV-A1-label-type-three {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.05px;
  color: #fafafa;
}

#MV-A1-label-type-four {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.05px;
  color: #234361;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.MV-A1-margin-bottom-12 {
  margin-bottom: 12px;
}

.MV-A1-margin-bottom-21 {
  margin-bottom: 21px;
}

.MV-A1-margin-bottom-26 {
  margin-bottom: 26px;
}

.MV-A1-margin-right-12 {
  margin-right: 12px;
}
