.PNDC-A1-col-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.PNDC-A1-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

#PNDC-A1-type-one-label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#PNDC-A1-type-two-label {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #234361;
}

.PNDC-A1-margin-top-24 {
  margin-top: 24px;
}
