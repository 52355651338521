.VRP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.VRP-A1-reports-actions-and-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1024px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  margin: auto;
  padding: 24px 16px;
}

.VRP-A1-reports-actions-container,
.VRP-A1-col-container,
.VRP-A1-get-reports-action-buttons-holder,
.VRP-A1-reports-data-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.VRP-A1-reports-data-container {
  align-items: flex-start;
}

.VRP-A1-get-reports-action-buttons-holder {
  align-items: flex-end;
}

.VRP-A1-col-container {
  flex-direction: column;
  align-items: flex-start;
}

.VRP-A1-button {
  padding: 0;
  justify-content: center;
}

.VRP-A1-vertical-line-decorator {
  height: 60px;
  border-right: 1px solid #e4e7eb;
}

.VRP-A1-horizontal-line-decorator {
  width: 976px;
  border-bottom: 1px solid #e4e7eb;
}

// -----------------------------------------------------------------------
// Width
// -----------------------------------------------------------------------
.VRP-A1-width-128 {
  width: 128px;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#VRP-A1-type-one-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#VRP-A1-type-two-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.05px;
  color: #425a70;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.VRP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.VRP-A1-margin-right-32 {
  margin-right: 32px;
}

.VRP-A1-margin-right-24 {
  margin-right: 24px;
}

.VRP-A1-margin-top-24 {
  margin-top: 24px;
}

.VRP-A1-margin-bottom-16 {
  margin-bottom: 16px;
}
