.SPDCDC-A1-wrapper,
.SPDCDC-A1-row-container,
.SPDCDC-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SPDCDC-A1-wrapper {
  padding: 0 16px 48px 16px;
}

.SPDCDC-A1-row-container {
  flex-direction: row;
}

#SPDCDC-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

.SPDCDC-A1-button {
  padding: 0;
  justify-content: center;
}

#SPDCDC-A1-label-type-two {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

// -----------------------------------------------------------------------
// Margin()
// -----------------------------------------------------------------------
.SPDCDC-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.SPDCDC-A1-margin-top-24 {
  margin-top: 24px;
}

.SPDCDC-A1-margin-top-32 {
  margin-top: 32px;
}

.SPDCDC-A1-margin-right-32 {
  margin-right: 32px;
}
