.RP-A1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 41px);
  border-top: 1px solid #e4e8eb;
  min-width: calc(100vw - 240px);
  width: fit-content;
}

.RP-A1-reports-type-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid #e4e8eb;
  min-width: 296px;
  min-height: calc(100vh - 41px);
  padding-bottom: 48px;
}

.RP-A1-report-type-container,
.RP-A1-report-type-container-selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  border-bottom: 1px solid #e4e8eb;
}

.RP-A1-report-type-container-selected {
  background-color: #fafafa;
}

.RP-A1-report-type-container:hover {
  background-color: #fafafa;
  cursor: pointer;
}

#RP-A1-label-type-one,
#RP-A1-label-type-one-selected {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
  text-transform: uppercase;
}

.RP-A1-label-type-one-selected {
  color: #084b8a;
}
