.RBDC-A1-body-container {
  padding: 8px 16px 24px 16px;
}

.RBDC-A1-footer-container {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e7eb;
  padding-top: 16px;
}

.RBDC-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.RBDC-A1-reschedule-details-actions-container,
.RBDC-A1-check-availability-btn-container {
  margin-bottom: 0;
}

.RBDC-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.RBDC-A1-small-col-container {
  width: fit-content;
  margin-right: 32px;
}

.RBDC-A1-text-container {
  width: 100%;
  margin-bottom: 8px;
}

#RBDC-A1-large-dark-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#RBDC-A1-large-light-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
}

#RBDC-A1-medium-light-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.RBDC-A1-refresh-btn-container {
  align-self: flex-end;
}

.RDBC-A1-alert-container {
  margin-bottom: 16px;
}
