.HLDC-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}

.HLDC-A1-wrapper {
  padding: 8px;
}

.HLDC-A1-row-container,
.HLDC-A1-col-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.HLDC-A1-col-container {
  flex-direction: column;
}

.HLDC-A1-add-holiday-btn-container {
  align-self: flex-end;
}

.HLDC-A1-top-container {
  padding-bottom: 32px;
}

.HLDC-A1-horizontal-line-decorator {
  width: 100%;
  border-top: 1px solid #e4e7eb;
}

.HLDC-A1-bottom-container {
  padding-top: 32px;
}

.HLDC-A1-align-self-center {
  align-self: center;
}

.HLDC-A1-padding-top-24 {
  padding-top: 24px;
}

.HLDC-A1-padding-bottom-24 {
  padding-bottom: 24px;
}

.HLDC-A1-configured-holidays-list-container {
  max-height: 280px;
  padding-bottom: 24px;
  overflow: auto;
}

// -----------------------------------------------------------------------
// label
// -----------------------------------------------------------------------
#HLDC-A1-label-type-one {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#HLDC-A1-label-type-two {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
  text-transform: uppercase;
}

#HLDC-A1-label-type-three {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

#HLDC-A1-label-type-four {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
}

// -----------------------------------------------------------------------
// margin
// -----------------------------------------------------------------------
.HLDC-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.HLDC-A1-margin-right-40 {
  margin-right: 40px;
}

.HLDC-A1-margin-right-56 {
  margin-right: 56px;
}

// -----------------------------------------------------------------------
// width
// -----------------------------------------------------------------------
.HLDC-A1-width-120 {
  width: 120px;
}
