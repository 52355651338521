.MP-A1-wrapper {
  width: 100%;
  min-width: 1034px;
}

.MP-A1-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: sticky;
  top: 0;
  min-width: fit-content;
  height: 108px;
  background-color: #fafafa;
  border-top: 1px solid rgba(67, 90, 111, 0.14);
  padding: 12px 40px;
}

.MP-A1-col-container,
.MP-A1-row-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.MP-A1-row-container {
  flex-direction: row;
}

#MP-A1-label-type-one {
  font-size: 11px;
  font-weight: 600;
  line-height: 1.45;
  letter-spacing: 0.3px;
  font-style: normal;
  font-stretch: normal;
  color: #66788a;
  text-transform: uppercase;
}

.MP-A1-vertical-line-decorator {
  height: 40px;
  border-right: 1px solid #e4e7eb;
}

.MP-A1-message-container {
  display: flex;
  flex-direction: row;
  width: 778px;
  height: 40px;
  margin-top: 16px;
  margin-left: 40px;
  background-color: #fdf8f3;
}

.MP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.MP-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}

.MP-A1-align-items-center {
  align-items: center;
}
