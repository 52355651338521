.GCF-A1-wrapper {
  width: calc(53vw - 240px);
  min-height: calc(53vh - 41px);
  max-height: calc(53vh - 41px);
  overflow: auto;
}

.GCF-A1-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 640px;
  padding: 24px 40px 24px 40px;
}

.GCF-A1-actions-wrapper {
  width: 520px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e4e7eb;
}

.GCF-A1-last-actions-wrapper {
  display: flex;
  width: 520px;
  padding-bottom: 0px;
}

.GCF-A1-col-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.GCF-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#GCF-A1-label-type-one {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

#GCF-A1-label-type-two {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.GCF-A1-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.GCF-A1-radio-btn span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
  align-self: flex-end;
}

.GCF-A1-input-field {
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.GCF-A1-btn {
  justify-content: center !important;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.GCF-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

.GCF-A1-margin-right-124 {
  margin-right: 124px;
}

.GCF-A1-margin-bottom-4 {
  margin-bottom: 4px;
}

.GCF-A1-margin-top-16 {
  margin-top: 16px;
}

// -----------------------------------------------------------------------
// Width
// -----------------------------------------------------------------------
.GCF-A1-width-128 {
  width: 128px;
}
