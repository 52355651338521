.CRDC-A1-body-content {
  margin-bottom: 24px;
}

.CRDC-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.CRDC-A1-row-container,
.CRDC-A1-col-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.CRDC-A1-col-container {
  flex-direction: column;
}

.CRDC-A1-label-container {
  width: 50px;
  margin-right: 48px;
}

#CRDC-A1-small-label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

.CRDC-A1-footer-content {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e7eb;
  padding-top: 16px;
}
