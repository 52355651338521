.H-A1-wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 40px;
}

#H-A1-label-type-one {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.05px;
  white-space: nowrap;
  color: #234361;
}
