.SP-A1-wrapper {
  width: 100%;
  min-width: 1104px;
}

.SP-A1-search-actions-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 818px;
  padding: 12px 40px;
  width: 100%;
  min-height: 188px;
  height: fit-content;
  background-color: #fafafa;
  z-index: auto;
  // position: sticky;
  // top: 0;
  border-top: 1px solid rgba(67, 90, 111, 0.14);
}

.SP-A1-label-container {
  width: fit-content;
  margin-bottom: 8px;
}

#SP-A1-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.SP-A1-row-container {
  display: flex;
  flex-direction: row;
  // margin-top: 18px;
}

.SP-A1-col-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.SP-A1-radio-btn-container {
  display: flex;
  flex-direction: row;
}

.SP-A1-radio-btn span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
  align-self: flex-end;
}

.SP-A1-find-booking-container,
.SP-A1-sport-select-container {
  margin-right: 24px;
}

.SP-A1-show-results-btn-container {
  justify-content: flex-end;
}

.SP-A1-message-container {
  display: flex;
  flex-direction: row;
  width: 778px;
  height: 40px;
  background-color: #fdf8f3;
  margin-left: 40px;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.SP-A1-margin-right-24 {
  margin-right: 24px;
}

.SP-A1-margin-top-14 {
  margin-top: 14px;
}
