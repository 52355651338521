.RA-A1-wrapper {
  width: 712px;
  min-height: calc(100vh - 41px);
  padding: 32px 40px;
}

// -----------------------------------------------------------------------
// Margin()
// -----------------------------------------------------------------------
.RA-A1-margin-bottom-32 {
  margin-bottom: 32px;
}

.RA-A1-margin-bottom-24 {
  margin-bottom: 24px;
}
