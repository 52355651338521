.period-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.filter-model {
  width: fit-content;
  border-radius: 10px;
  padding: 5px;
  right: 1%;
  top: 50px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  min-height: 300px;
  min-width: fit-content;
  background-color: white;
  position: absolute;
  z-index: 999;
  box-shadow: 0px 4px 8px rgba(36, 55, 66, 0.1),
    0px 1px 3px rgba(36, 55, 66, 0.08);
}
.date-range-picker-div {
  width: 320px;

  margin-top: 9px;
  background-color: white;
  z-index: 999;
  border-left: 1px solid lightgray;
  padding: 10px;
}

.custom-input {
  box-sizing: border-box;

  width: 112px;
  height: 44px;

  background: #ffffff;
  border: 1px solid rgba(113, 121, 113, 0.16);
  border-radius: 8px;
}

.apply-button {
  width: 7em;
  height: 4ex;
  background-color: #0f4d88;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-weight: normal;
  color: white;

  cursor: pointer;
}
