.CALS-A1-select-input-field-container label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;

  height: 17px;
}
