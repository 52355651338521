.ST-A1-wrapper {
  margin-top: 13px;
  margin-left: 41px;
  max-height: calc(100vh - 256px);
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: auto;
}

.ST-A1-table-container {
  width: 1200px;
  min-width: 1038px;
  border: 1px solid #e4e7eb;
  border-bottom: 0;
}

.ST-A1-table-text-header-cell,
.ST-A1-table-cell {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.ST-A1-table-row {
  min-height: 100px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ST-A1-table-row:hover {
  cursor: default !important;
}

.ST-A1-table-cell-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  overflow-wrap: break-word;
}

.ST-A1-member-avatar-and-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  min-height: 24px;
}

.ST-A1-avatar {
  align-self: flex-start !important;
}

.ST-A1-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ST-A1-status-hint-circle,
.ST-A1-upcoming,
.ST-A1-completed,
.ST-A1-ongoing {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 2px;
}

.ST-A1-upcoming {
  background-color: #d9822b;
}

.ST-A1-completed {
  background-color: #1070ca;
}

.ST-A1-ongoing {
  background-color: #47b881;
}

.ST-A1-cancelled {
  background-color: #ec4c47;
}

.ST-A1-action-btn {
  box-shadow: none !important;
}

.ST-A1-pagination-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  width: 1038px;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#ST-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
}

#ST-A1-label-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#ST-A1-label-type-three {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #425a70;
}

#ST-A1-label-type-four {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #1070ca;
  text-decoration: underline;
}

#ST-A1-label-type-four:hover {
  cursor: pointer;
  color: #084b8a;
}

#ST-A1-label-type-five {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

// -----------------------------------------------------------------------
// Flex Basis
// -----------------------------------------------------------------------
.ST-A1-flex-basis-200 {
  flex-basis: 200px !important;
}

.ST-A1-flex-basis-230 {
  flex-basis: 230px !important;
}

.ST-A1-flex-basis-185 {
  flex-basis: 185px !important;
}

.ST-A1-flex-basis-310 {
  flex-basis: 310px !important;
}

.ST-A1-flex-basis-128 {
  flex-basis: 128px !important;
}

.ST-A1-flex-basis-180 {
  flex-basis: 180px !important;
}

.ST-A1-flex-basis-220 {
  flex-basis: 220px !important;
}

.ST-A1-flex-basis-110 {
  flex-basis: 110px !important;
}

.ST-A1-flex-basis-160 {
  flex-basis: 160px !important;
}

.ST-A1-flex-basis-165 {
  flex-basis: 165px !important;
}

.ST-A1-flex-basis-120 {
  flex-basis: 120px !important;
}

.ST-A1-flex-basis-48 {
  flex-basis: 48px !important;
}
