// -----------------------------------------------------------------------
// Custom Styling for React DatePicker
// -----------------------------------------------------------------------
.CDP-A1-wrapper {
  .react-datepicker__input-container input,
  .react-datepicker__input-container input:disabled {
    width: 124px !important;
    height: 32px !important;
    padding: 0px 8px !important;
    cursor: pointer !important;
    border-radius: 3px !important;
    background-image: linear-gradient(to top, #f4f5f7, #ffffff) !important;
    border: none !important;
    color: #425a70 !important;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol" !important;
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09) !important;
    font-size: 12px !important;
  }

  .react-datepicker__input-container input:disabled {
    cursor: not-allowed !important;
    color: #bdbdbf !important;
  }

  .react-datepicker-popper {
    z-index: 6 !important;
  }
}
