.LP-A1-button {
  justify-content: center;
}

.LP-A1-wrapper {
  min-width: calc(100vw - (100vw - 100%));
  width: fit-content;
  background-color: #ffffff;
}

.LP-A1-company-logo-top-nav-bar {
  width: 100%;
  height: 64px;
  background-color: #084b8a;
  padding: 14px 48px;
}

.LP-A1-playo-logo {
  width: 129px;
  height: 36px;
}

.LP-A1-body-container {
  display: flex;
  flex-direction: row;
}

.LP-A1-body-left-section-container {
  position: relative;
  min-width: fit-content;
  max-width: 720px;
  max-height: calc(100vh - 64px);
  overflow: hidden;
}

.LP-A1-football-ground-image {
  max-height: calc(100vh - 64px);
}

.LP-A1-hello-partner-label-container {
  position: absolute;
  top: 22%;
  left: 12%;
}

.LP-A1-body-right-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 548px;
  max-height: calc(100vh - 64px);
  overflow: auto;
  padding: 48px;
}

.LP-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.LP-A1-text-input-field-container label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
}

.LP-A1-text-input-field-container {
  height: fit-content;
}

.LP-A1-footer-container {
  padding-top: 34px;
  border-top: 1px solid #e4e7eb;
  width: 100%;
}

.LP-A1-logo-container {
  width: 140px;
}

.LP-A1-logo-container:hover {
  cursor: pointer;
}

.LP-A1-store-logo {
  width: 100%;
  height: auto;
}

.LP-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.LP-A1-google-play-app-store-logo-container {
  align-items: center;
}

.LP-A1-descriptive-logo-wrapper {
  position: absolute;
  top: 52%;
  left: 12%;
}

.LP-A1-descriptive-logo-container {
  width: 52px;
  height: 42px;
}

.LP-A1-descriptive-logo {
  width: 100%;
  height: 100%;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#LP-A1-label-type-one {
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.9;
  letter-spacing: -0.27px;
  color: #ffffff;
}

#LP-A1-label-type-two {
  font-size: 56px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.9;
  letter-spacing: -0.38px;
  color: #ffffff;
}

#LP-A1-label-type-three {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: -0.22px;
  color: #234361;
}

#LP-A1-label-type-four {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

#LP-A1-label-type-five {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #00783e;
  text-decoration: underline;
}

#LP-A1-label-type-six {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}

#LP-A1-label-type-five:hover {
  cursor: pointer;
}

#LP-A1-label-type-seven {
  font-size: 13.2px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #ffffff;
}

// -----------------------------------------------------------------------
// Width
// -----------------------------------------------------------------------
.LP-A1-width-76 {
  max-width: 76px;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.LP-A1-margin-bottom-12 {
  margin-bottom: 12px;
}

.LP-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.LP-A1-margin-bottom-32 {
  margin-bottom: 32px;
}

.LP-A1-margin-bottom-40 {
  margin-bottom: 40px;
}

.LP-A1-margin-bottom-140 {
  margin-bottom: 140px;
}

.LP-A1-margin-right-32 {
  margin-right: 32px;
}

.LP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.LP-A1-margin-right-64 {
  margin-right: 64px;
}
