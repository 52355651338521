.DB-A1-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 96px;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(67, 90, 111, 0.14);
  border: 1px solid #dbe1e4;
  background-image: linear-gradient(to top, #f4f5f7, #ffffff),
    linear-gradient(to bottom, #ffffff, #ffffff);
}

.DB-A1-btn {
  padding: 0 !important;
  justify-content: center !important;
  font-size: 9px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;

  svg {
    width: 9px !important;
    height: 9px !important;
  }
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#DB-A1-label-type-one {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -0.06px;
  text-align: center;
  color: #234361;
}

#DB-A1-label-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.04px;
  color: #66788a;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.DB-A1-margin-bottom-6 {
  margin-bottom: 6px;
}

.DB-A1-margin-bottom-3 {
  margin-bottom: 3px;
}

.DB-A1-margin-top-27 {
  margin-top: 27px;
}
