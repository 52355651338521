.VMPP-A1-body-container {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  background-color: #fafafa;
  overflow: auto;
  padding-top: 16px;
}

.VMPP-A1-body-contents-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1030px;
  height: fit-content;
  min-height: calc(100vh - 142px);
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
  padding: 16px;
  margin: auto;
}

.VMPP-A1-action-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.VMPP-A1-vertical-line-decorator {
  height: 40px;
  border-right: 1px solid #e4e7eb;
  margin: 0 24px 0 48px;
}
