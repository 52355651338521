.MPT-A1-wrapper {
  margin-top: 16px;
  // max-height: calc(100vh - 180px);
  height: fit-content;
  // overflow-y: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.MPT-A1-table-container {
  width: 998px;
  min-width: 998px;
  border: 1px solid #e4e7eb;
  border-bottom: 0;
}

.MPT-A1-table-row {
  min-height: 100px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MPT-A1-table-row:hover {
  cursor: default !important;
}

.MPT-A1-table-text-header-cell,
.MPT-A1-table-cell {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.MPT-A1-flex-basis-280 {
  flex-basis: 280px !important;
}

.MPT-A1-flex-basis-320 {
  flex-basis: 320px !important;
}

.MPT-A1-flex-basis-210 {
  flex-basis: 210px !important;
}

.MPT-A1-flex-basis-140 {
  flex-basis: 140px !important;
}

.MPT-A1-flex-basis-48 {
  flex-basis: 48px !important;
}

.MPT-A1-table-cell-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  word-break: break-all;
}

#MPT-A1-label-type-one {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#MPT-A1-label-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#MPT-A1-label-type-three {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #d9822b;
}

#MPT-A1-label-type-four {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #1070ca;
  text-decoration: underline;
}

#MPT-A1-label-type-four:hover {
  cursor: pointer;
  color: #084b8a;
}

.MPT-A1-pagination-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  width: 998px;
}

.MPT-A1-action-btn-container {
  display: flex;
  align-items: center;
  height: 48px;
  padding-top: 4px;
}
