.RT-A1-reports-table-container {
  width: 992px;
  min-width: 992px;
  border: 1px solid #e4e7eb;
  border-bottom: none;
}

.RT-A1-table-text-header-cell,
.RT-A1-table-cell {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.RT-A1-table-cell-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  word-break: break-word;
}

.RT-A1-action-btn-container {
  display: flex;
  align-items: center;
  height: 48px;
  width: 48px;
  justify-content: center;
}

.RT-A1-table-row {
  min-height: 100px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#RT-A1-label-type-one {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}

#RT-A1-label-type-two {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#RT-A1-label-type-three {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
}

// -----------------------------------------------------------------------
// Flex Basis
// -----------------------------------------------------------------------
.RT-A1-flex-basis-180 {
  flex-basis: 180px !important;
}

.RT-A1-flex-basis-90 {
  flex-basis: 90px !important;
}

.RT-A1-flex-basis-230 {
  flex-basis: 230px !important;
}

.RT-A1-flex-basis-159 {
  flex-basis: 159px !important;
}

.RT-A1-flex-basis-141 {
  flex-basis: 141px !important;
}

.RT-A1-flex-basis-134 {
  flex-basis: 134px !important;
}

.RT-A1-flex-basis-56 {
  flex-basis: 56px !important;
}

.RT-A1-flex-basis-112 {
  flex-basis: 112px !important;
}

.RT-A1-flex-basis-91 {
  flex-basis: 91px !important;
}

.RT-A1-flex-basis-167 {
  flex-basis: 167px !important;
}

.RT-A1-flex-basis-211 {
  flex-basis: 211px !important;
}

.RT-A1-flex-basis-172 {
  flex-basis: 172px !important;
}

.RT-A1-flex-basis-222 {
  flex-basis: 222px !important;
}

.RT-A1-flex-basis-175 {
  flex-basis: 175px !important;
}

.RT-A1-flex-basis-250 {
  flex-basis: 250px !important;
}

.RT-A1-flex-basis-189 {
  flex-basis: 189px !important;
}

.RT-A1-flex-basis-129 {
  flex-basis: 129px !important;
}

.RT-A1-flex-basis-130 {
  flex-basis: 130px !important;
}

.RT-A1-flex-basis-164 {
  flex-basis: 164px !important;
}

.RT-A1-flex-basis-257 {
  flex-basis: 257px !important;
}

.RT-A1-flex-basis-259 {
  flex-basis: 259px !important;
}

.RT-A1-flex-basis-286 {
  flex-basis: 286px !important;
}

.RT-A1-flex-basis-190 {
  flex-basis: 190px !important;
}

.RT-A1-flex-basis-138-point-25 {
  flex-basis: 138.25px !important;
}

.RT-A1-flex-basis-241 {
  flex-basis: 241px !important;
}

.RT-A1-flex-basis-182 {
  flex-basis: 182px !important;
}

.RT-A1-flex-basis-168 {
  flex-basis: 168px !important;
}

.RT-A1-flex-basis-140 {
  flex-basis: 140px !important;
}

.RT-A1-flex-basis-124 {
  flex-basis: 124px !important;
}

.RT-A1-flex-basis-100 {
  flex-basis: 100px !important;
}

.RT-A1-flex-basis-120 {
  flex-basis: 120px !important;
}
