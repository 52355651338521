@import "reset";
@import "./header.scss";
@import "./searchTable.scss";
@import "./transactionsDialogContent.scss";
@import "./countrySelectMenu.scss";
@import "./bookingCheckout.scss";
@import "./addNewMemberDialogContent.scss";
@import "./transitions.scss";
@import "./membersTable.scss";
@import "./pagination.scss";
@import "./memberDetailsOnDrawer.scss";
@import "./creditsRechargeDialogContent.scss";
@import "./creditsValidityDialogContent.scss";
@import "./customizedDatePicker.scss";
@import "./updateMemberDetailsDialogContent.scss";
@import "./setPinDialogContent.scss";
@import "./updatePinDialogContent.scss";
@import "./customizedTimePicker.scss";
@import "./rescheduleBookingDialogContent.scss";
@import "./membersPage.scss";
@import "./searchPage.scss";
@import "./membershipPackagesPage.scss";
@import "./createMembershipPackagesPage.scss";
@import "./pageHeadingAndActionsNav.scss";
@import "./noMatchingResultsMessage.scss";
@import "./membershipPackagesTable.scss";
@import "./customizedPagination.scss";
@import "./membershipPackagesDialogContent.scss";
@import "./membershipPackagesCheckoutPage.scss";
@import "./clubActivityListSelect.scss";
@import "./allMembersListPage.scss";
@import "./generateCouponsForm.scss";
@import "./subscribedPackagesDialogContent.scss";
@import "./payNowDialogContent.scss";
@import "./pricingPage.scss";
@import "./editPricingPage.scss";
@import "./specialPriceDateChangeDialogContent.scss";
@import "./reportsPage.scss";
@import "./reportSummary.scss";
@import "./reportActions.scss";
@import "./reportsPeriodPicker.scss";
@import "./clubHandlerSelect.scss";
@import "./transactionReportsFilter.scss";
@import "./getReportsActionButtons.scss";
@import "./bookingReportsFilter.scss";
@import "./viewReportsPage.scss";
@import "./reportsTable.scss";
@import "./loginPage.scss";
@import "./homePageActionBar.scss";
@import "./holidayListingDialogContent";
@import "./premiumFeaturePage.scss";
@import "./addDeskPersonDialogContent.scss";
@import "./changePasswordDialogContent.scss";
@import "./customInlineAlert.scss";
@import "./bulkForm.scss";
@import "./contactSupportDialogContent.scss";
@import "./blankPageGuide.scss";
@import "./comingSoon.scss";
@import "./mobileView.scss";
@import "./bookingActions.scss";
@import "./bookingDetailsDrawerActions.scss";
@import "./bulkBookingDetailsOnSearchScreenActions.scss";
@import "./cancelBookingDialogContent.scss";
@import "./slotTable.scss";
@import "./memberDetailsDrawerActions.scss";
@import "./regularBookingDetailsDrawerContent.scss";
@import "./cartOnCalendarPage.scss";
@import "./dataBox.scss";
@import "./barChartBox.scss";
@import "./lineChartBox.scss";
@import "./viewMembershipPackagesPage";
@import "./clubCourtListSelect.scss";
@import "./coupon.scss";
@import "./dashboard.scss";
// -----------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

// -----------------------------------------------------------------------
// Font
// -----------------------------------------------------------------------
// font-style-stretch-normal
.font-style-stretch-normal {
  font-style: normal;
  font-stretch: normal;
}

// font-weight-normal
.font-weight-normal {
  font-weight: normal;
}

// font-weight-500
.font-weight-500 {
  font-weight: 500;
  font-size: 14px;
}

// font-weight-600
.font-weight-600 {
  font-weight: 600;
}

.upper-case-text {
  text-transform: uppercase;
}

// font-size-11
.font-size-11 {
  font-size: 11px;
}

// font-size-12
.font-size-12 {
  font-size: 12px;
}

// font-size-14
.font-size-14 {
  font-size: 14px;
}

// font-size-16
.font-size-16 {
  font-size: 16px;
}

// line-height-1.33
.line-height-1.33 {
  line-height: 1.33;
}

// letter-spacing-normal
.letter-spacing-normal {
  letter-spacing: normal;
}

// capitalize
.capitalize {
  text-transform: capitalize;
}

// color-#425a70
.color-#425a70 {
  color: #425a70;
}

// color-#234361
.color-#234361 {
  color: #234361;
}

// -----------------------------------------------------------------------
// BulkBookingDetailsOnSearchPage Pay now dialog
// -----------------------------------------------------------------------
#BBDOSP-A1-label-5 {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#BBDOSP-A1-label-6 {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
}

// -----------------------------------------------------------------------
// Flex
// -----------------------------------------------------------------------
// display-flex
.display-flex {
  display: flex;
}

// row-flex
.row-flex {
  flex-direction: row;
}

// col-flex
.col-flex {
  flex-direction: column;
}

// flex-align-start
.flex-align-start {
  align-items: flex-start !important;
}

// flex-align-end
.flex-align-end {
  align-items: flex-end;
}

// flex-align-center
.flex-align-center {
  align-items: center;
}

// flex-justify-start
.flex-justify-start {
  justify-content: flex-start !important;
}

// flex-justify-end
.flex-justify-end {
  justify-content: flex-end;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

// // -----------------------------------------------------------------------
// // Custom Styling for React DatePicker and
// // Ant Design TimePicker Component
// // -----------------------------------------------------------------------
// .ant-time-picker-input,
// .react-datepicker__input-container input {
//   border-radius: 3px;
//   background-image: linear-gradient(to top, #f4f5f7, #ffffff);
//   border: none;
//   color: #425a70;
//   font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
//     Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//     "Segoe UI Symbol";
//   box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14),
//     inset 0 -1px 1px 0 rgba(67, 90, 111, 0.09);
//   font-size: 12px;
// }

// .react-datepicker__input-container input {
//   width: 112px;
//   height: 32px;
//   padding: 0px 8px;
//   cursor: pointer;
// }

// .react-datepicker-popper {
//   z-index: 6;
// }

// -----------------------------------------------------------------------
// Material UI Drawer
// -----------------------------------------------------------------------
.drawer-holder-div > div {
  z-index: auto !important;
}

// -----------------------------------------------------------------------
// Others
// -----------------------------------------------------------------------

// list-item
.list-item {
  height: 32px;
  padding: 0 !important;
  background-color: transparent !important;
}

// nested-list-item
.nested-list-item,
.nested-list-item-selected {
  padding: 0 !important;
  padding-left: 56px !important;
  height: 32px !important;
  background-color: transparent !important;
}

.nested-list-item-selected {
  background-color: #1070ca !important;
}

//remove-btn
.remove-btn,
.add-extra-btn {
  padding-right: 0 !important;
}

//react datepicker text align
.react-datepicker__input-container input {
  text-align: center;
}

// margin-top-25
.margin-top-25 {
  margin-top: 25px;
}

// letter spacing -0.1px
.letter-spacing-0.1 {
  letter-spacing: -0.1px;
}

// button
.button {
  padding: 0 !important;
  justify-content: center !important;
}

.booking-checkout-text {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.1px;
  color: #234361;
}

.select-field-container select {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
}

.inline-alert > div:first-child {
  align-self: flex-start;
}

.date-picker {
  //height: 32px !important;
  //display: flex;
  //padding: 0 8px !important;
  border-radius: 3px !important;
  color: rgb(102, 120, 138) !important;
}

.date-picker input {
  //margin: auto !important;
  color: transparent !important;
  text-align: center !important;
  text-shadow: 0 0 0 rgb(102, 120, 138) !important;

  &:focus {
    outline: none !important;
  }
}
