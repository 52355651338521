.MT-A1-wrapper {
  margin-top: 16px;
  margin-left: 41px;
  max-height: calc(100vh - 190px);
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.MT-A1-table-container {
  width: 1150px;
  min-width: 1100px;
  border: 1px solid #e4e7eb;
  border-bottom: 0;
}

.MT-A1-table-row {
  min-height: 100px !important;
  height: fit-content !important;
  align-items: flex-start !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MT-A1-table-row:hover {
  cursor: default;
}

.MT-A1-table-text-header-cell,
.MT-A1-table-cell {
  flex-basis: 200px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.MT-A1-table-action-btn-text-header-cell,
.MT-A1-action-btn-container-table-cell {
  flex-basis: 48px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.MT-A1-availed-packages-text-header-cell,
.MT-A1-availed-packages-table-cell {
  flex-basis: 280px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

// .MT-A1-availed-packages-table-cell :hover {
//   background-color: #e4e7eb;
// }

.MT-A1-member-avatar-and-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  min-height: 24px;
}

#MT-A1-big-underline-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #1070ca;
  text-decoration: underline;
}

#MT-A1-big-underline-label:hover {
  cursor: pointer;
  color: #084b8a;
}

.MT-A1-member-mobile-and-email-container,
.MT-A1-member-credits-and-validity-container,
.MT-A1-member-availed-packages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 200px;
  padding-top: 4px;
}

.MT-A1-member-availed-packages-container {
  max-width: 320px;
}

.MT-A1-membership-package-data-content {
  margin-bottom: 8px;
}

.MT-A1-member-actions-menu-and-btn-container {
  display: flex;
  align-items: center;
  height: 48px;
  padding-top: 4px;
}

#MT-A1-small-label {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#MT-A1-big-dark-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#MT-A1-label-type-six {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
  text-decoration: underline;
}

#MT-A1-label-type-six:hover {
  cursor: pointer;
  color: #234361;
}

#MT-A1-label-type-seven {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

.MT-A1-pagination-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  width: 968px;
}

#MT-A1-small-uppercase-type-one-label,
#MT-A1-small-uppercase-type-two-label,
#MT-A1-small-uppercase-type-three-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
  margin-right: 0.5em;
}

#MT-A1-small-uppercase-type-one-label {
  color: #d9822b;
}
#MT-A1-small-uppercase-type-two-label {
  color: #ec4c47;
}
#MT-A1-small-uppercase-type-three-label {
  color: #007489;
}

.MT-A1-avatar {
  align-self: flex-start !important;
}
