.BCB-A1-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.BCB-A1-chart-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BCB-A1-chart-wrapper {
  box-shadow: 0px 1px 4px 0px rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
}

.BCB-A1-navigation-btn-container:hover {
  cursor: pointer;
}

.BCB-A1-navigation-btn-container-disabled:hover {
  cursor: not-allowed;
}

.BCB-A1-navigation-btn-container,
.BCB-A1-navigation-btn-container-disabled {
  position: relative;
}

.BCB-A1-icon-container {
  position: absolute;
}

.BCB-A1-chevron-left-icon-container {
  top: 39%;
  left: 20%;
}

.BCB-A1-chevron-right-icon-container {
  top: 39%;
  right: 20%;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#BCB-A1-label-type-one {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #234361;
  text-transform: uppercase;
}
// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.BCB-A1-margin-bottom-8 {
  margin-bottom: 8px;
}
