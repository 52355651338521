.MPP-A1-wrapper {
  width: 100%;
  min-width: 1064px;
}

.MPP-A1-action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // position: sticky;
  // top: 0;
  min-width: fit-content;
  padding-left: 40px;
  height: 108px;
  background-color: #fafafa;
  z-index: 5;
  border-top: 1px solid rgba(67, 90, 111, 0.14);
}

.MPP-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

// .MPP-A1-vertical-line-decorator {
//   height: 40px;
//   border-right: 1px solid #e4e7eb;
//   margin: 0 56px;
// }

.MPP-A1-dashboard-container {
  padding: 18px 40px;
  max-height: calc(100vh - 163px);
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: auto;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.MPP-A1-margin-right-48 {
  margin-right: 48px;
}

.MPP-A1-margin-right-70 {
  margin-right: 70px;
}
