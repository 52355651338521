.ST-B1-wrapper {
  width: 100%;
  max-height: calc(100vh - 168px);
  overflow: auto;
  position: relative;
  z-index: 0 !important;
}

.ST-B1-table {
  position: relative;
  border-collapse: collapse;
}

.ST-B1-table-header {
  th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 4;
    padding: 18px 0 18px 20px;
    box-shadow: inset 0 -1px 0 0 rgba(228, 231, 235, 1);
    max-width: 136px;
    word-wrap: break-word;
    vertical-align: middle;
    text-align: center;
  }

  th:first-child {
    left: 0;
    z-index: 5;
    box-shadow: inset -1px -1px 0 0 rgba(228, 231, 235, 1);
    padding: 18px;
  }

  th:last-child {
    z-index: 5;
    box-shadow: inset -1px -1px 0 0 rgba(228, 231, 235, 1);
    padding-right: 20px;
  }

  #ST-B1-label-type-one {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -0.1px;
    text-align: center;
    color: #234361;
  }
}

.ST-B1-table-body {
  th,
  td {
    box-shadow: inset 0 -1px 0 0 rgba(228, 231, 235, 1);
    vertical-align: middle;
  }

  th {
    position: sticky;
    left: 0;
    box-shadow: inset -1px -1px 0 0 rgba(228, 231, 235, 1);
    padding: 18px 18px 19px 18px;
    background: #fff;
    z-index: 4;
    text-align: center;
  }

  td {
    padding: 18px 0 19px 20px;
  }

  td:last-child {
    box-shadow: inset -1px -1px 0 0 rgba(228, 231, 235, 1);
    padding-right: 20px;
  }

  #ST-B1-label-type-two {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    text-align: center;
    color: #234361;
    white-space: nowrap;
  }
}
