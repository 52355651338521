.RBDDC-A1-container {
  width: 368px;
  height: calc(100vh - 258px);
  min-height: calc(100vh - 258px);
  margin: 16px auto 0 auto;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

.RBDDC-A1-selected-sport-name-container {
  display: flex;
  flex-direction: row;
  width: 336px;
  margin: 16px 16px 0 16px;
  justify-content: space-between;
}

.RBDDC-A1-booking-details-content-large-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 336px;
  border-bottom: 1px solid #eff0f3;
  margin: 24px 16px 24px 16px;
}

.RBDDC-A1-details-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 336px;
  margin-top: 16px;
}

.RBDDC-A1-type-one-detail-container {
  display: flex;
  flex-direction: column;
  width: 160px;
}

.RBDDC-A1-type-two-detail-container {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.RBDDC-A1-break-word-all {
  word-break: break-all;
}

.RBDDC-A1-btn {
  justify-content: center !important;
  padding: 0 !important;
}

.RBDDC-A1-border-none {
  border: none;
}

.RBDDC-A1-remarks-container {
  max-height: 72px;
  overflow-y: auto;
}

.RBDDC-A1-booking-details-actions-wrapper {
  width: 100%;
  position: fixed;
  margin: 0px 0px 0 -16px;
  display: flex;
  bottom: 0px;
  height: 120px;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 -5px 10px -5px rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
  z-index: 3;
}

.only-checkin {
  width: 100%;
  margin-top: 10px;
  display: flex;
  bottom: 0px;

  align-items: center;
  border-radius: 5px;

  background-color: #ffffff;
  z-index: 3;
}

// -----------------------------------------------------------------------
//  Label
// -----------------------------------------------------------------------
#RBDDC-A1-type-one-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

#RBDDC-A1-type-two-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
}

#RBDDC-A1-type-three-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.05px;
  color: #234361;
}

#RBDDC-A1-type-four-label,
.RBDDC-A1-text-area-input {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.04px;
  color: #66788a;
}

#RBDDC-A1-type-five-label {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#RBDDC-A1-type-six-label {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #234361;
}

// -----------------------------------------------------------------------
//  Pay Now Dialog
// -----------------------------------------------------------------------
.RBDDC-A1-pay-now-row,
.RBDDC-A1-pay-now-last-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.RBDDC-A1-pay-now-last-row {
  justify-content: flex-end;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid #e4e7eb;
}

// -----------------------------------------------------------------------
//  Width
// -----------------------------------------------------------------------
.RBDDC-A1-width-224 {
  width: 224px;
  max-width: 224px;
}

.RBDDC-A1-width-fit-content {
  width: fit-content;
}

.RBDDC-A1-width-160 {
  width: 160px;
  max-width: 160px;
}

.RBDDC-A1-width-80 {
  width: 80px;
  max-width: 80px;
}

// -----------------------------------------------------------------------
//  Margin
// -----------------------------------------------------------------------
.RBDDC-A1-margin-top-16 {
  margin-top: 16px;
}

.RBDDC-A1-margin-top-0 {
  margin-top: 0;
}

.RBDDC-A1-margin-left-24 {
  margin-left: 24px;
}

.RBDDC-A1-margin-right-88 {
  margin-right: 88px;
}

.RBDDC-A1-margin-right-8 {
  margin-right: 8px;
}

.RBDDC-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.RBDDC-A1-margin-bottom-36 {
  margin-bottom: 36px;
}

.RBDDC-A1-margin-bottom-68 {
  margin-bottom: 68px;
}

.RBDDC-A1-margin-top-4 {
  margin-top: 4px;
}
