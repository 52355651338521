#ANMDC-A1-large-label,
.ANMDC-A1-input-container label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.ANMDC-A1-input-container textarea,
.ANMDC-A1-input-container input {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  resize: none;
}

.ANMDC-A1-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 336px;
  margin: 16px auto 4px auto;
}

.ANMDC-A1-label-container {
  margin-bottom: 8px;
}

.ANMDC-A1-col-elements-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 28px;
}

.ANMDC-A1-row-elements-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ANMDC-A1-country-select-menu-container {
  margin-right: 8px;
}

.ANMDC-A1-invalid-mobile-num-msg-container {
  margin-top: 8px;
}

.ANMDC-A1-radio-input {
  align-items: center;
}

.ANMDC-A1-radio-input span {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435a6f;
}

.ANMDC-A1-registration-fee-input-container {
  margin-right: 16px;
}
