.CVDC-A1-body-content {
  margin-bottom: 24px;
}

.CVDC-A1-row-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.CVDC-A1-radio-btn-row {
  margin-bottom: 0;
  padding-left: 8px;
}

.CVDC-A1-date-picker-row {
  padding-left: 32px;
}

#CVDC-A1-small-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: #425a70;
}

.CVDC-A1-radio-input {
  align-items: center;
}

.CVDC-A1-radio-input span {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.CVDC-A1-footer-content {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e4e7eb;
  padding-top: 16px;
}
