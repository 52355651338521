.CP-A1-pagination {
  li {
    border-radius: 3px !important;
  }

  .ant-pagination-item a,
  .ant-pagination-item-active a {
    font-size: 11px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: 0.3px !important;
    text-align: center !important;
    color: #66788a !important;
    font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  .ant-pagination-item-active a {
    color: #1070ca !important;
  }

  .ant-pagination-item {
    border: none !important;
    background-color: transparent !important;
  }

  .ant-pagination-item a {
    padding: 6px !important;
    margin: 0 !important;
  }

  .ant-pagination-item-active {
    background-color: rgba(16, 112, 202, 0.08) !important;
  }
}
