.CHS-A1-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.CHS-A1-select-input-field-container label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
  margin-bottom: 8px;
}

#CHS-A1-type-one-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.CHS-A1-margin-bottom-8 {
  margin-bottom: 8px;
}
