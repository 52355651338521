.CIA-A1-inline-alert-input-msg-container {
  margin-top: 4px;
}

.CIA-A1-inline-alert {
  align-items: center;
}
.CIA-A1-inline-alert div:first-child {
  align-self: flex-start;
}

#CIA-A1-invalid-input-msg,
#CIA-A1-info-input-msg {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bf0e08;
}

#CIA-A1-info-input-msg {
  color: #084b8a;
}
