.ADPDC-A1-wrapper {
  padding: 16px 8px 4px 8px;
}
.ADPDC-A1-row-container,
.ADPDC-A1-col-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.ADPDC-A1-col-container {
  flex-direction: column;
}

.ADPDC-A1-margin-bottom-24 {
  margin-bottom: 24px;
}

.ADPDC-A1-margin-bottom-4 {
  margin-bottom: 4px;
}

.ADPDC-A1-margin-right-8 {
  margin-right: 8px;
}

.ADPDC-A1-input-field-container label,
#ADPDC-A1-type-one-label {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.ADPDC-A1-horizontal-line-decorator {
  border-bottom: 1px solid #e4e7eb;
  width: 624px;
}

.ADPDC-A1-align-self-flex-end {
  align-self: flex-end;
}

.ADPDC-A1-align-items-baseline {
  align-items: baseline;
}

.ADPDC-A1-invalid-input-msg-container {
  margin-top: 4px;
}

.ADPDC-A1-inline-alert {
  align-items: center;
}
.ADPDC-A1-inline-alert div:first-child {
  align-self: flex-start;
}

#ADPDC-A1-invalid-input-msg {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bf0e08;
}

.ADPDC-A1-margin-bottom-32 {
  margin-bottom: 32px;
}

.ADPDC-A1-eye-icon-container {
  min-height: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 14px;
  border-radius: 3px;
}

.ADPDC-A1-eye-icon-container:hover {
  cursor: pointer;
}
