.COCP-A1-cart-data-container {
  width: 368px;
  height: calc(100vh - 324px);
  overflow-y: auto;
  margin: 16px auto 0 auto;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
}

.COCP-A1-selected-sport-name-container {
  width: 336px;
  margin: 16px 16px 0 16px;
}

.COCP-A1-cart-item-wrapper {
  width: 336px;
  border-bottom: 1px solid #eff0f3;
  margin: 24px auto;
}

.COCP-A1-cart-item-court-name-price-container {
  margin: 8px auto;
  display: flex;
  justify-content: space-between;
}

.COCP-A1-remove-cart-item-button-container {
  margin: 8px auto;
  display: flex;
  justify-content: flex-end;
}

.COCP-A1-total-price-cart-actions-wrapper {
  position: absolute;
  margin: -4px 16px 0 16px;
  width: 368px;
  height: 184px;
  border-radius: 5px;
  box-shadow: 0 -5px 10px -5px rgba(67, 90, 111, 0.47);
  background-color: #ffffff;
  z-index: 3;
}

.COCP-A1-total-text-price-container {
  width: 336px;
  height: 71px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e7eb;
}

// -----------------------------------------------------------------------
//  Label
// -----------------------------------------------------------------------
#COCP-A1-type-one-label {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #234361;
}

#COCP-A1-type-two-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

#COCP-A1-type-three-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  color: #425a70;
}

#COCP-A1-type-four-label {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ec4c47;
  text-decoration: line-through;
}

#COCP-A1-type-five-label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.05px;
  color: #234361;
}

// -----------------------------------------------------------------------
//  Margin
// -----------------------------------------------------------------------
.COCP-A1-margin-top-25 {
  margin-top: 25px;
}
