.BA-A1-wrapper {
  display: flex;
  justify-content: space-between;
  width: 344px;
}

.BA-A1-button {
  justify-content: center !important;
  padding: 0 !important;
}
