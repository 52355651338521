.BF-A1-wrapper {
  width: calc(100vw - 240px);
  min-height: calc(100vh - 41px);
  max-height: calc(100vh - 41px);
  border-top: 1px solid #e4e8eb;
  overflow: auto;
}

.BF-A1-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 640px;
  padding: 24px 40px 24px 40px;
}

.BF-A1-actions-wrapper {
  width: 560px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e4e7eb;
}

.BF-A1-inline-alert-actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 560px;
  height: 40px;
  padding: 0 12px;
  background-color: #f1fbfc;

  .CIA-A1-inline-alert-input-msg-container {
    margin: 0 !important;

    span {
      font-size: 14px !important;
      font-weight: bold !important;
    }
  }
}

.BF-A1-inline-alert-actions-wrapper:hover {
  cursor: pointer;
  background-color: #d2eef3;
}

.BF-A1-last-actions-wrapper {
  width: 560px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.BF-A1-col-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.BF-A1-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#BF-A1-label-type-one {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
  text-transform: uppercase;
}

#BF-A1-label-type-two {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.BF-A1-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.BF-A1-input-field {
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.BF-A1-btn {
  justify-content: center;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.BF-A1-margin-bottom-16 {
  margin-bottom: 16px;
}

.BF-A1-margin-bottom-4 {
  margin-bottom: 4px;
}

.BF-A1-margin-right-124 {
  margin-right: 124px;
}
