.HPAB-A1-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  // min-width: fit-content;
  min-width: 100%;
  height: 112px;
  background-color: #fafafa;
  padding: 24px 40px;
  border-top: 1px solid #e4e8eb;
}

.HPAB-A1-left-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.HPAB-A1-right-actions-container {
  align-self: center;
}

.HPAB-A1-cart-icon-wrapper {
  position: relative;
  align-self: center;
}

.HPAB-A1-cart-icon-wrapper:hover {
  cursor: pointer;
}

.HPAB-A1-shopping-cart-icon,
.HPAB-A1-cart-slot-count-pill {
  position: absolute;
}

.HPAB-A1-cart-slot-count-pill {
  top: -8px;
  left: 8px;
}

// -----------------------------------------------------------------------
// Label
// -----------------------------------------------------------------------
#HPAB-A1-label-type-one {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.HPAB-A1-margin-right-32 {
  margin-right: 32px;
}

.HPAB-A1-margin-right-124 {
  margin-right: 124px;
}

.HPAB-A1-margin-bottom-8 {
  margin-bottom: 8px;
}
