.PP-A1-wrapper {
  width: 100%;
  min-width: 1064px;
}

.PP-A1-button {
  padding: 0 !important;
  justify-content: center !important;
}

.PP-A1-date-range-button {
  width: fit-content !important;
  padding: 0 12px !important;
  justify-content: center !important;
}

.PP-A1-action-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // position: sticky;
  // top: 0;
  min-width: fit-content;
  padding-top: 16px;
  padding-left: 40px;
  min-height: 140px;
  max-height: 140px;
  background-color: #fafafa;
  border-top: 1px solid #e4e8eb;
}

.PP-A1-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.PP-A1-col-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.PP-A1-vertical-line-decorator {
  height: 40px;
  border-right: 1px solid #e4e7eb;
}

#PP-A1-label-type-one {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #66788a;
  text-transform: uppercase;
}

.PP-A1-court-pills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.PP-A1-price-config-details-wrapper {
  width: 100%;
  max-height: calc(100vh - 220px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 40px;
}

.PP-A1-price-config-details-container {
  width: 867px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.PP-A1-price-type-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  background-color: #fafafa;
  border: 1px solid #e4e7eb;
}

.PP-A1-add-pricing-btn-container {
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PP-A1-border-r-l-b {
  border-right: 1px solid #e4e7eb;
  border-left: 1px solid #e4e7eb;
  border-bottom: 1px solid #e4e7eb;
}

#PP-A1-label-type-two {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #425a70;
  text-transform: uppercase;
}

#PP-A1-label-type-three {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.1px;
  color: #234361;
}

#PP-A1-label-type-four {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.3px;
  color: #234361;
  text-transform: uppercase;
}

#PP-A1-label-type-five {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#PP-A1-label-type-six {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #425a70;
}

#PP-A1-label-type-seven {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #66788a;
}

.PP-A1-pricing-slot-details-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.PP-A1-pricing-slot-days-and-actions-container {
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #e4e7eb;
}

.PP-A1-special-datepicker-actions-container {
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.PP-A1-price-slot-details-wrapper {
  width: 100%;
  max-width: 100%;
  min-height: 56px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-wrap: break-word;
  padding: 16px 97.5px;
  border-bottom: 1px solid #e4e7eb;
}

.PP-A1-price-slot-details-wrapper:last-child {
  border: none;
}

.PP-A1-justify-content-flex-end {
  justify-content: flex-end;
}

// -----------------------------------------------------------------------
// Margin
// -----------------------------------------------------------------------
.PP-A1-margin-bottom-8 {
  margin-bottom: 8px;
}

.PP-A1-margin-right-32 {
  margin-right: 32px;
}

.PP-A1-margin-right-8 {
  margin-right: 8px;
}

.PP-A1-margin-top-16 {
  margin-top: 16px;
}

.PP-A1-margin-top-24 {
  margin-top: 24px;
}

.PP-A1-margin-top-32 {
  margin-top: 32px;
}

.PP-A1-margin-right-150 {
  margin-right: 150px;
}

.PP-A1-margin-right-120 {
  margin-right: 120px;
}

.PP-A1-margin-right-354 {
  margin-right: 354px;
}

.PP-A1-margin-right-16 {
  margin-right: 16px;
}

// -----------------------------------------------------------------------
// Width
// -----------------------------------------------------------------------
.PP-A1-width-150 {
  min-width: 150px;
  max-width: 150px;
}

.PP-A1-width-100 {
  min-width: 100px;
  max-width: 100px;
}
